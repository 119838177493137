<template>
  <div class="container text-center">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img src="../../assets/images/common/empty_message.png" class="w-100" />
        <h3 class="text-dark-gray mt-5">Messages</h3>
        <p class="text-muted" v-if="userType == 'broker'">
          {{
            $t(
              "Applicants can send a message via this feature. These messages will appear here"
            )
          }}
        </p>
        <p v-else>
          {{
            $t(
              "Messages is a feature that helps you converse with applicants and landlords. Let’s send your first message."
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  computed: {
    ...mapGetters("auth", ["userType"]),
  },
  methods: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>
