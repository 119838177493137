<template>
  <div>
    <Chat v-if="!getLoadings.messages && messages.data && messages.data.length" />
    <EmptyMessage v-if="!getLoadings.messages && (!messages.data || messages.data.length == 0)" />
  </div>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import EmptyMessage from "./EmptyMessage.vue";
import Chat from "./Chat.vue";

export default {
  components: { EmptyMessage, Chat },
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters("messages", ["messages"]),
    ...mapGetters("auth", ["getUserType"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  methods: {
    ...mapActions("messages", ["getMessages"]),
  },
  created() {
    // this.getMessages({ type: this.getUserType });
    this.getMessages({ type: this.getUserType });
  },
};
</script>
  
<style lang="scss">

</style>