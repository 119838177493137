<template>
  <div class="messages row p-0 m-0">
    <div class="col col-md-3 bg-white p-0 m-0">
      <div class="p-3 border-bottom">
        <input type="text" :placeholder="$t('Search here...')" class="form-control" />
      </div>

      <div class="userList">
        <div
          class="userMessage p-3 border-bottom"
          v-for="message in messages.data"
          :key="message.id"
          :class="{ active: activeMessage && message.id == activeMessage.id }"
          @click="selectMessage(message)"
        >
          <div class="d-flex justify-content-between">
            <div class="fs-16px fw-700" v-if="message.listing">
              {{ message.listing.title }}
            </div>
            <span class="fs-14px text-muted">{{
              message.last_message_at | moment("calendar")
            }}</span>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <div
              class="fs-14px fw-medium"
              v-if="getUserType == 'user' && message.listing"
            >
              {{ message.listing.broker.officeName }}
            </div>
            <div class="fs-14px fw-medium" v-if="getUserType == 'broker'">
              {{ message.user.code }}
            </div>
            <div
              class="online"
              v-if="getUserType == 'broker' && message.broker_seen_at == null"
            ></div>
            <div
              class="online"
              v-if="getUserType == 'user' && message.user_seen_at == null"
            ></div>
          </div>
          <div class="text-muted fs-14px mt-2">{{ message.last_message }}</div>
        </div>
      </div>
    </div>
    <div class="col col-md-6 m-0 p-4" v-if="activeConversation">
      <!-- <div class="card">
                <div class="card-body p-2">
                    <a href="javascript:void()" class="text-muted">
                        <i class="fa-regular fa-trash-can text-muted cursor-pointer ml-3"></i>
                        <i class="fa-solid fa-circle-info text-muted cursor-pointer ml-3"></i>
                    </a>
                </div>
            </div> -->
      <div class="conversation mb-3">
        <div v-for="con in activeConversation.data" :key="con.id">
          <div class="receiver" v-if="con.sender != getUserType">
            <div class="message" v-if="con.type == 'text'">
              {{ con.data.text }}
            </div>
            <div class="message" v-if="con.type == 'file'">
              <img
                :src="con.data.url"
                alt=""
                v-if="['png', 'jpg', 'jpeg'].includes(con.data.extension.toLowerCase())"
              />
              <a
                :href="con.data.url"
                v-if="con.data.extension == 'pdf'"
                target="_blank"
                >{{ con.data.name }}</a
              >
            </div>
            <div class="text-muted mt-2">{{ con.created_at | moment("calendar") }}</div>
          </div>
          <div class="sender" v-if="con.sender == getUserType">
            <div class="message" v-if="con.type == 'text'">
              {{ con.data.text }}
            </div>
            <div class="message" v-if="con.type == 'file'">
              <img
                :src="con.data.url"
                alt=""
                v-if="['png', 'jpg', 'jpeg'].includes(con.data.extension.toLowerCase())"
              />
              <a
                :href="con.data.url"
                v-if="con.data.extension == 'pdf'"
                target="_blank"
                >{{ con.data.name }}</a
              >
            </div>
            <div class="time text-muted mt-2">
              {{ con.created_at | moment("calendar") }}
            </div>
          </div>
        </div>
        <div class="sender" v-if="sendingFile">
          <div class="message">
            <i class="fa-solid fa-image"></i>
          </div>
          <div class="time text-muted mt-2">Sending...</div>
        </div>
      </div>
      <div>
        <textarea
          rows="1"
          class="form-control p-3"
          placeholder="Write your message here..."
          v-model="message"
        ></textarea>
      </div>
      <div class="text-muted fs-14px mt-2" v-if="messageAlert">
        {{ $t("message_alert") }}
      </div>
      <div class="text-danger fs-14px mt-2" v-if="error">{{ error }}</div>
      <div class="fs-14px mt-2" v-if="file">{{ file.name }}</div>

      <div class="mt-3 d-flex justify-content-between">
        <input type="file" ref="file" style="display: none" @change="onFileUpload" />
        <i
          class="fa-solid fa-paperclip cursor-pointer attachment"
          @click="$refs.file.click()"
        ></i>
        <button
          class="btn btn-primary btn-sm p-3 sendBtn"
          @click="send"
          :disabled="getLoadings.send_message"
        >
          Send
          <b-spinner
            v-if="getLoadings.send_message"
            small
            label="Spinning"
            variant="white"
          ></b-spinner>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-else
          >
            <path
              d="M10.0468 3.67342L4.34018 0.82009C0.506843 -1.09991 -1.06649 0.473423 0.853509 4.30676L1.43351 5.46676C1.60018 5.80676 1.60018 6.20009 1.43351 6.54009L0.853509 7.69342C-1.06649 11.5268 0.500176 13.1001 4.34018 11.1801L10.0468 8.32676C12.6068 7.04676 12.6068 4.95342 10.0468 3.67342ZM7.89351 6.50009L4.29351 6.50009C4.02018 6.50009 3.79351 6.27342 3.79351 6.00009C3.79351 5.72676 4.02018 5.50009 4.29351 5.50009L7.89351 5.50009C8.16684 5.50009 8.39351 5.72676 8.39351 6.00009C8.39351 6.27342 8.16684 6.50009 7.89351 6.50009Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="col col-md-3 bg-white m-0" v-if="activeListing && activeListing.media">
      <ListingCard :listing="activeListing">
        <template v-slot:controls>
          <RouterLink
            class="btn btn-white text-primary btn-block"
            :to="{
              name: 'Listing',
              params: { listingId: activeListing.id },
            }"
          >
            {{ $t("View property details") }}
          </RouterLink>
        </template>
      </ListingCard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Card from "@/components/general/listing/Listing.vue";

export default {
  components: { ListingCard: Card },
  data() {
    return {
      searchQuery: "",
      file: null,
      error: "",
      sendingFile: false,
    };
  },
  computed: {
    ...mapGetters("messages", [
      "messages",
      "activeConversation",
      "activeMessage",
      "activeListing",
      "messageAlert",
      "messageText",
    ]),
    ...mapGetters("auth", ["getUserType"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    message: {
      get() {
        return this.messageText;
      },
      set(value) {
        this.setMessageText(value);
      },
    },
  },
  methods: {
    ...mapActions("messages", ["getMessages", "selectMessage", "sendMessage"]),
    ...mapMutations("messages", ["setMessageText"]),
    async send() {
      let data = {
        message: this.message,
        listing_id: this.activeMessage.listing_id,
        user_id: this.activeMessage.user_id,
        type: "text",
      };

      if (this.message) {
        await this.sendMessage(data);
        this.message = "";
      }

      if (this.file) {
        data.file = this.file;
        data.type = this.file.name.split(".").pop();
        this.sendingFile = true;
        await this.sendMessage(data);
        this.file = null;
        this.sendingFile = false;
      }
    },
    onFileUpload() {
      this.error = "";
      let file = this.$refs.file.files[0];
      console.log(this.$refs.file, this.file);
      let extension = file.name.split(".").pop();
      let allowed_types = ["pdf", "png", "jpg"];

      if (!allowed_types.includes(extension.toLowerCase())) {
        this.error = "*Only image and PDF is allowed";
      }

      this.file = file;
    },
  },
  created() {
    console.log("created");
    // this.getMessages({ type: this.getUserType });
  },
};
</script>

<style lang="scss">
.messages {
  .col {
    height: calc(100vh - 80px);
  }

  .online {
    width: 8px;
    height: 8px;
    background: #0a9250;
    border-radius: 50%;
  }

  .userList {
    height: calc(100% - 82px);
    overflow-y: auto;
  }

  .userMessage:hover {
    cursor: pointer;
    background-color: $light-gray;
  }

  .userMessage.active {
    background: #cce6d2;
  }
}

.conversation {
  height: calc(100% - 120px);
  overflow-y: auto;

  .receiver {
    margin-top: 1rem;

    .message {
      background: #ffffff;
      border: 1.5px solid #c9c9c9;
      border-radius: 0px 8px 8px 8px;
      padding: 16px;
      max-width: 70%;
      width: fit-content;
      font-weight: 500;

      img {
        max-width: 400px;
        border-radius: 10px;
      }
    }
  }

  .sender {
    margin-top: 1rem;

    .message {
      background: #cce6d2;
      border: 1.5px solid #c9c9c9;
      border-radius: 8px 8px 0px 8px;
      padding: 16px;
      max-width: 70%;
      margin-right: 10px;
      margin-left: auto;
      width: fit-content;
      font-weight: 500;

      img {
        max-width: 400px;
        border-radius: 10px;
      }
    }

    .time {
      margin-right: 10px;
      text-align: right;
    }
  }
}

.sendBtn {
  border-radius: 8px;
}
</style>
